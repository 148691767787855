import Cards from "./routes/Cards.svelte";
import EditCard from "./routes/EditCard.svelte";
import Items from "./routes/Items.svelte";
import Login from "./routes/Login.svelte";
import Calendar from "./routes/Calendar.svelte";
import Stock from "./routes/Stock.svelte";
import Storage from "./routes/Storage.svelte";
import EditStock from "./routes/EditStock.svelte";
import Contacts from "./routes/Contacts.svelte";
import EditContact from "./routes/EditContact.svelte";
import Sales from "./routes/Sales.svelte";
import StorageTransfers from "./routes/StorageTransfers.svelte";
import EditStorageTransfer from "./routes/EditStorageTransfer.svelte";
import CreateStorageTransfer from "./routes/CreateStorageTransfer.svelte";
import ImportTransfer from "./routes/ImportTransfer.svelte";
import EditSale from "./routes/EditSale.svelte";
import SaleReports from "./routes/SaleReports.svelte";
import ViewSaleReport from "./routes/ViewSaleReport.svelte";
import StorageRevisions from "./routes/StorageRevisions.svelte";
import EditRevision from "./routes/EditRevision.svelte";
import QuickSearch from "./routes/QuickSearch.svelte";
import StorageTransferHistory from "./routes/StorageTransferHistory.svelte";

export default [
  {
    path: "/crm/contacts",
    component: Contacts,
  },
  {
    path: "/crm/contacts/:id",
    component: EditContact,
  },
  {
    path: "/crm/sales",
    component: Sales,
  },
  {
    path: "/crm/sales/:id",
    component: EditSale,
  },
  {
    path: "/crm/sale-reports/",
    component: SaleReports,
  },
  {
    path: "/crm/sale-reports/:id",
    component: ViewSaleReport,
  },
  {
    path: "/warehouse/items",
    component: Items,
  },
  {
    path: "/warehouse/cards",
    component: Cards,
  },
  {
    path: "/warehouse/cards/:id",
    component: EditCard,
  },
  {
    path: "/stock",
    component: Stock,
  },
  {
    path: "/stock/:id",
    component: EditStock,
  },
  {
    path: "/transfers",
    component: StorageTransfers,
  },
  {
    path: "/search",
    component: QuickSearch,
  },
  {
    path: "/transfers/add",
    component: CreateStorageTransfer,
  },
  {
    path: "/transfers/import",
    component: ImportTransfer,
  },
  {
    path: "/transfers/:id",
    component: EditStorageTransfer,
  },
  {
    path: "/storage",
    component: Storage,
  },
  {
    path: "/revisions",
    component: StorageRevisions,
  },
  {
    path: "/revisions/:id",
    component: EditRevision,
  },
  {
    path: "/storage/:id",
    component: Storage,
  },
  {
    path: "/transfer-history",
    component: StorageTransferHistory,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/calendar",
    component: Calendar,
  },
  {
    path: "*",
    component: Login,
  },
];
